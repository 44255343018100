<template>
  <div>
    <div class="wrapper section-blue">
      <!-- section dashboard -->
      <section
        id="section-support"
        class="section section-blue jpadding jpadding-20 section-unfixed"
      >
        <router-link :to="{ name: 'Download' }" class="jcard jcard-dash">
          <h4><i class="fad fa-download"></i></h4>
          <h4>Download</h4>
          <small
            >Get the Talkii Supervisor APK for Android directly on to your
            device.</small
          >
        </router-link>

        <router-link
          :to="{ name: 'UserGuide' }"
          class="jcard jcard-dash"
        >
          <h4><i class="fad fa-book"></i></h4>
          <h4>User Guide</h4>
          <small
            >We composed a detailed user manual to guide you through the entire
            Talkii experience.</small
          >
        </router-link>

        <a
          href="https://talkii.app/faq"
          target="_blank"
          class="jcard jcard-dash"
        >
          <h4><i class="fad fa-telescope"></i></h4>
          <h4>Faq</h4>
          <small
            >Look in here first, some of your questions might have already been
            answered.</small
          >
        </a>

        <a
          href="https://talkii.app/contact"
          target="_blank"
          class="jcard jcard-dash nolink"
        >
          <h4><i class="fad fa-life-ring"></i></h4>
          <h4>Let us help</h4>
          <small>Contact us directly to find a solution together.</small>
        </a>
      </section>
    </div>
  </div>
</template>

<script>
//import firebase from "firebase/app";
// import C from "@/constants";
import PLANS from "@/plans";

export default {
  name: "Dashboard",
  data() {
    return {
      subStatusList: PLANS.STATUS_INDEX,
      subTypeList: PLANS.TYPE_INDEX,
      firstname: "",
      lastname: "",
      homeTitle: "Bonjour ",

      //userId: firebase.auth().currentUser.uid,
    };
  },
};
</script>

<style scoped></style>
